import React, { useEffect, useState } from "react";
import {
  ParallaxProvider,
  ParallaxBanner,
  Parallax,
  BannerLayer,
} from "react-scroll-parallax";
import { motion } from "framer-motion";
import ContactForm from './components/ContactForm';

const ScrollIndicator = () => {
  return (
    <div className="absolute bottom-4 left-0 right-0 flex justify-center z-20">
      <a href="#1" className="animate-bounce" style={{ cursor: 'pointer' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m0 0l-4-4m4 4l4-4"
          />
        </svg>
      </a>
    </div>
  );
};

const App = () => {
  const [visibleSections, setVisibleSections] = useState({
    vision: false,
    ideas: false,
    previousWork: false,
    contact: false,
  });

  const background: BannerLayer = {
    image: "/media/1.jpg",
    translateY: [0, 50],
    opacity: [1, 0.3],
    scale: [1.05, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const headline: BannerLayer = {
    translateY: [0, 30],
    scale: [1, 1.05, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="inset center flex items-center justify-center h-full">
        <h1 className="headline text-5xl md:text-8xl lg:text-9xl font-handwritten text-white" style={{ marginTop: "-2.5rem" }}>
          Sage Media
        </h1>
      </div>
    ),
  };

  const foreground: BannerLayer = {
    image: "/media/2.png",
    translateY: [0, 15],
    scale: [1, 1.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const gradientOverlay: BannerLayer = {
    opacity: [0, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: <div className="gradient inset" />,
  };

  const handleScroll = () => {
    const visionSection = document.getElementById("vision");
    const ideasSection = document.getElementById("ideas");
    const previousWorkSection = document.getElementById("previous-work");
    const contactSection = document.getElementById("contact");

    if (visionSection && visionSection.getBoundingClientRect().top < window.innerHeight) {
      setVisibleSections((prev) => ({ ...prev, vision: true }));
    }

    if (ideasSection && ideasSection.getBoundingClientRect().top < window.innerHeight) {
      setVisibleSections((prev) => ({ ...prev, ideas: true }));
    }

    if (previousWorkSection && previousWorkSection.getBoundingClientRect().top < window.innerHeight) {
      setVisibleSections((prev) => ({ ...prev, previousWork: true }));
    }

    if (contactSection && contactSection.getBoundingClientRect().top < window.innerHeight) {
      setVisibleSections((prev) => ({ ...prev, contact: true }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ParallaxProvider>
      <div className="relative">
        <ParallaxBanner layers={[background, headline, foreground, gradientOverlay]} className="h-screen">
          <ScrollIndicator />
        </ParallaxBanner>

        <Parallax speed={-20}>
          <div id="vision" className="h-screen flex items-center justify-center px-4">
            <motion.div
              className="text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: visibleSections.vision ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <h1 className="text-white text-5xl md:text-7xl lg:text-9xl font-extrabold leading-tight" style={{ fontFamily: 'Playfair Display, serif' }}>
                Bringing Your Vision <br className="hidden md:block" /> to Life
              </h1>
            </motion.div>
          </div>
        </Parallax>

        <div className="bg-white text-black relative z-10 space-y-12 py-8 px-4 md:px-8">
          <motion.section
            id="ideas"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: visibleSections.ideas ? 1 : 0, y: visibleSections.ideas ? 0 : 50 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4">
              Making Ideas Happen
            </h1>
            <p className="text-base md:text-lg mb-6" id="1">
              Just because your business is small, doesn’t mean your site needs to be. 
            </p>
          </motion.section>

          <motion.section
            id="previous-work"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: visibleSections.previousWork ? 1 : 0, y: visibleSections.previousWork ? 0 : 50 }}
            transition={{ duration: 0.6 }}
            className="flex justify-start"
          >
            <div className="w-full max-w-md rounded-lg shadow-lg overflow-hidden">
              <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">Previous Work</h2>
              <div className="relative w-full h-80 overflow-hidden rounded-lg">
                <motion.div
                  className="absolute inset-0 flex flex-col justify-end"
                  style={{ 
                    backgroundImage: `url(/media/bg1.png)`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%' 
                  }}
                >
                <div className="bg-black bg-opacity-50 p-4 text-center">
                  <h3 className="text-lg font-semibold text-white">Photography Portfolio</h3>
                  <p className="text-sm text-white">A clean portfolio site showcasing a variety of photography and videography projects, aimed at attracting potential clients.</p>
                </div>

                </motion.div>
              </div>
            </div>
          </motion.section>

          <motion.section
            id="contact"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: visibleSections.contact ? 1 : 0, y: visibleSections.contact ? 0 : 50 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Let’s Connect!</h2>
            <p className="text-base md:text-lg mb-6">
            Interested in working together? I’d love to hear from you! Just fill out the form below, and I’ll get back to you within 24 hours.
            </p>
            <ContactForm />
          </motion.section>
        </div>
        <footer className="bg-black text-white py-2">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm">© 2024 Sage Media. All rights reserved.</p>
          <p className="text-sm">sage@sage-media.co</p>
        </div>
      </footer>

      </div>
    </ParallaxProvider>
  );
};

export default App;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { motion } from 'framer-motion';

interface FormData {
  name: string;
  email: string;
  message: string;
  'h-captcha-response': string;
  access_key: string;
}

const ContactForm: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const steps = [
    { label: "What's your name?", key: "name" },
    { label: "What's your email?", key: "email" },
    { label: "What would you like to say?", key: "message" },
    { label: "Captcha", key: "captcha" },
  ];

  const onHCaptchaChange = (token: string | null) => {
    setValue('h-captcha-response', token || '');
  };

  const onSubmit = async (data: FormData) => {
    setIsLoading(true); 
    console.log(data);

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    console.log(result);

    if (response.ok) {
      setResponseMessage(result.message || 'Thank you for your submission');
      setIsError(false);
    } else {
      setResponseMessage(result.message || 'Something went wrong. Please try again.');
      setIsError(true);
    }

    setIsLoading(false);
  };

  const nextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (currentStep === 2 && event.key === 'Enter') {
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault(); 
      if (currentStep < steps.length - 1) {
        nextStep(); 
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-between p-5 md:p-10">
      <form className="w-full md:w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="access_key" value="a44d5408-9dee-436d-8ca1-35df8fba3ce4" />
        <input
          type="hidden"
          {...register('access_key')}
          defaultValue="a44d5408-9dee-436d-8ca1-35df8fba3ce4"
        />

        <motion.div
          key={currentStep}
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          exit={{ opacity: 0 }} 
          transition={{ duration: 0.3 }} 
        >
          {currentStep === 0 && (
            <div className="mb-5">
              <label htmlFor="name" className="text-xl font-medium text-indigo-800">
                {steps[0].label} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder="John Doe"
                className="w-full mt-1 px-2 py-1 border-b-2 border-neutral-400 focus:border-black placeholder:text-neutral-300"
                {...register('name', { required: true })}
                onKeyDown={handleKeyPress} 
              />
            </div>
          )}

          {currentStep === 1 && (
            <div className="mb-5">
              <label htmlFor="email" className="text-xl font-medium text-indigo-800">
                {steps[1].label} <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                placeholder="email@example.com"
                className="w-full mt-1 px-2 py-1 border-b-2 border-neutral-400 focus:border-black placeholder:text-neutral-300"
                {...register('email', { required: true })}
                onKeyDown={handleKeyPress}
              />
            </div>
          )}

          {currentStep === 2 && (
            <div className="mb-5">
              <label htmlFor="message" className="text-xl font-medium text-indigo-800">
                {steps[2].label} <span className="text-red-500">*</span>
              </label>
              <textarea
                id="message"
                placeholder="Message goes here!"
                className="w-full mt-1 px-2 py-1 border-b-2 border-neutral-400 focus:border-black placeholder:text-neutral-300"
                {...register('message', { required: true })}
                rows={4}
                onKeyDown={handleKeyPress}
              />
            </div>
          )}

          {currentStep === 3 && (
            <div className="mb-5">
              <HCaptcha
                sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
                onVerify={onHCaptchaChange}
              />
            </div>
          )}
        </motion.div>

        <div className="flex justify-between mt-5">
          {currentStep > 0 && (
            <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-700 text-white rounded">
              Back
            </button>
          )}
          {currentStep < steps.length - 1 ? (
            <button type="button" onClick={nextStep} className="px-4 py-2 bg-gray-700 text-white rounded">
              Next
            </button>
          ) : (
            <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
              {isLoading ? 'Loading...' : 'Submit Form'} 
            </button>
          )}
        </div>

        {responseMessage && (
          <div className={`mt-5 ${isError ? 'text-red-600' : 'text-green-600'}`}>
            {responseMessage}
          </div>
        )}
      </form>

      <div className="w-full md:w-1/3 pl-0 md:pl-10 mt-5 md:mt-0">
        <h2 className="text-2xl font-bold">Contact Details</h2>
        <p className="mt-2 text-lg">You can reach me at:</p>
        <p className="mt-2">Email: <a className="text-blue-600" href="mailto:sage@sage-media.co">sage@sage-media.co</a></p>
      </div>
    </div>
  );
};

export default ContactForm;
